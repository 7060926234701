import React from 'react';
import { useEffect, useState } from 'react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Button, Modal } from 'react-bootstrap';

import {
    Details_Ludo_Game_Template_List_Get,
    Get_Ludo_Categories_List_Get,
    Save_Ludo_Game_Template_Post,
    Update_Ludo_Game_Template_Put
} from 'api/ApiConst';
import { callGetApi, callPostApi, callPutApi } from 'api/ApiCaller';

import { isEmpty } from 'utils/TextUtils';

let boardTypeKeyValeOptions = [];
let playerCountKeyPairOptions = []
let limitTypeOptions = [];
let tokenCountOptions = [];
let botTypeOptions = [];
let tokenStartPositionTypeOptions = [];

const TemplateAddEditNew = ({ show, hideModal, recordId, handleRefresh }) => {
    const defaultObj = {
        boardType: { label: "Regular", value: "REGULAR" },
        templateTitle: "",
        betAmount: "",
        feeAmount: "",
        playerCount: "",
        category: "",
        minimumPlayerToStart: "",
        totalNumberOfWinners: "",
        everyWinnerWinningPercentage: [0],
        minimumPlayerToStart: "",
        turnTime: 10,
        graceTime: 10,
        gameStartWaitingTime: 10,
        resultWaitingTime: 10,
        limit: "",
        limitValue: "",
        skipDiceTurnIfNotRolled: false,
        skipPlayTurnIfNotPlayed: false,
        startBySixOnly: false,
        capturePointsOnTokenCapture: false,
        tokenStartPosition: "",
        boardRule: "",
        botLevelType: "",
    }
    const [record, setRecord] = useState({ ...defaultObj });
    const [minPlayerToStartKeyValueOptions, setMinPlayerToStartKeyValueOptions] = useState([...playerCountKeyPairOptions])
    const [winnerOptions, setWinnerOptions] = useState([]);
    const [winnersWinningPercentageInput, setWinnersWinningPercentageInput] = useState([null]);
    const [categories, setCategories] = useState([]);

    boardTypeKeyValeOptions = [];
    playerCountKeyPairOptions = []
    limitTypeOptions = [];
    tokenCountOptions = [];
    botTypeOptions = [];
    tokenStartPositionTypeOptions = [];

    process.env.REACT_APP_Board_Type.split(",").map((boardType) => {
        boardTypeKeyValeOptions.push({ label: boardType, value: boardType })
    })
    process.env.REACT_APP_Limit_Type.split(",").map((limitType) => {
        limitTypeOptions.push({ label: limitType, value: limitType })
    })

    if (process.env.REACT_APP_Bot_Allowed === "true") {
        process.env.REACT_APP_Bot_Type.split(",").map((botType) => {
            botTypeOptions.push({ label: botType, value: botType })
        })
    }
    process.env.REACT_APP_Start_Position_Type.split(",").map((playerCount) => {
        tokenStartPositionTypeOptions.push({ label: playerCount, value: playerCount })
    })
    process.env.REACT_APP_Player_Count.split(",").map((playerCount) => {
        playerCountKeyPairOptions.push({ label: parseFloat(playerCount), value: parseFloat(playerCount) })
    })
    process.env.REACT_APP_Token_Count.split(",").map((tokerCount) => {
        tokenCountOptions.push({ label: parseFloat(tokerCount), value: parseFloat(tokerCount) })
    })

    useEffect(() => {
        if (process.env.REACT_APP_Category_Allowed === "true") {
            callGetApi(Get_Ludo_Categories_List_Get, {}, (response) => {
                let res = response.responsePacket;
                let catData = res.map((item) => ({
                    key: item,
                    value: item,
                    label: item
                }));
                setCategories(catData);
            });
        }
    }, []);

    useEffect(() => {
        if (recordId) {
            callGetApi(Details_Ludo_Game_Template_List_Get.replaceAll("{recordID}", recordId), {}, (response) => {
                const res = response.responsePacket;

                // Set Winner Options
                let _winnerOptions = [];
                // for (let i = 1; i < res.playerCount; i++) {
                //     _winnerOptions.push({ value: i, label: i, });
                // } // Shashi
                _winnerOptions.push({ value: 1, label: 1, });
                setWinnerOptions(_winnerOptions);

                // set min Player To Start
                let _minPlayerToStartKeyValueOptions = [];
                for (let i = (res.totalNumberOfWinners + 1); i <= res.playerCount; i++) {
                    if (i % 2 === 0) {
                        _minPlayerToStartKeyValueOptions.push({ value: i, label: i, });
                    }
                }

                setMinPlayerToStartKeyValueOptions(_minPlayerToStartKeyValueOptions);

                // set board Type 
                const _boardType = boardTypeKeyValeOptions.find((obj) => obj.value === res.boardType);

                // set Player count 
                const _playerCount = playerCountKeyPairOptions.find((obj) => obj.value === res.playerCount);

                // set minimumPlayerToStart
                const _minimumPlayerToStart = _minPlayerToStartKeyValueOptions.find((obj) => obj.value === res.minimumPlayerToStart);

                // set Total Winner 
                const _totalNumberOfWinners = _winnerOptions.find((obj) => obj.value === res.totalNumberOfWinners);

                // set Limit Type
                const _limitType = limitTypeOptions.find((obj) => obj.value === res.limit);

                // set Token Count
                const _tokenCount = tokenCountOptions.find((obj) => obj.value === res.tokenCount);

                // set Start Token Position
                const _tokenStartPositionType = tokenStartPositionTypeOptions.find((obj) => obj.value === res.tokenStartPosition);

                // set Bot Level Type
                const _botLevelType = botTypeOptions.find((obj) => obj.value === res.botLevelType);

                // set category
                const _category = categories.find((obj) => obj.value === res.category);

                setWinnersWinningPercentageInput([...res["everyWinnerWinningPercentage"]]);
                setRecord({
                    ...res,
                    boardType: _boardType,
                    playerCount: _playerCount || "",
                    minimumPlayerToStart: _minimumPlayerToStart || "",
                    totalNumberOfWinners: _totalNumberOfWinners || "",
                    limit: _limitType || "",
                    tokenCount: _tokenCount || "",
                    tokenStartPosition: _tokenStartPositionType,
                    botLevelType: _botLevelType,
                    category: _category
                });
            });
        }
    }, [recordId, categories]);

    // set total Winners and Winning Percentage columns
    const handleWinnersCount = (e) => {
        // setRecord(pre => ({ ...pre, totalNumberOfWinners: e, minimumPlayerToStart: "" }));
        let _minimumPlayerToStart = record.minimumPlayerToStart;
        if (_minimumPlayerToStart && e.value >= _minimumPlayerToStart?.value) {
            _minimumPlayerToStart = "";
        }
        // setRecord(pre => ({ ...pre, totalNumberOfWinners: e, minimumPlayerToStart: record.minimumPlayerToStart?.value <= e.value ? "" : record.minimumPlayerToStart }));
        setRecord(pre => ({ ...pre, totalNumberOfWinners: e, minimumPlayerToStart: _minimumPlayerToStart }));

        // set Minimum Player to Win Key-Pair List
        const _minPlayerToStartKeyValueOptions = [];
        for (let i = (e.value + 1); i <= record.playerCount?.value; i++) {
            if (i % 2 === 0) {
                _minPlayerToStartKeyValueOptions.push({ value: i, label: i, });
            }
        }
        setMinPlayerToStartKeyValueOptions(_minPlayerToStartKeyValueOptions);

        // set Winner Winning Percentage Columns  
        const _winnersWinningPercentageInput = [...winnersWinningPercentageInput];
        // _winnersWinningPercentageInput.length = 0;
        for (let i = 0; i < e.label; i++) {
            if (e.label < _winnersWinningPercentageInput.length) {
                _winnersWinningPercentageInput.splice(e.value, _winnersWinningPercentageInput.length - i)
            }
            if (_winnersWinningPercentageInput.length < e.label) {
                _winnersWinningPercentageInput.push(null);
            }
        }
        setWinnersWinningPercentageInput(_winnersWinningPercentageInput);
    };

    // set Player Winning Percentage Values 
    const handleWinnersWinningPercentage = (e, index) => {
        let temp = winnersWinningPercentageInput.map((i) => i);
        temp[index] = parseFloat(e.target.value);
        setWinnersWinningPercentageInput(temp);
    };

    // Input text change
    const handleTextChange = (e) => {
        const { id, type, value, checked } = e.target;
        if (type === "number") {
            if (id === "betAmount" || id === "feeAmount") {
                setRecord(pre => ({ ...pre, [id]: parseFloat(value) }));
            } else {
                setRecord(pre => ({ ...pre, [id]: parseInt(value) }));
            }
            return;
        } else if (type === "checkbox") {
            setRecord(pre => ({ ...pre, [id]: checked }));
            return;
        }
        setRecord(pre => ({ ...pre, [id]: value }));
    };

    // Select Change
    const handleSelectChange = (e, key) => {
        if (key === "playerCount") {

            let _minimumPlayerToStart = record.minimumPlayerToStart;
            if (!isEmpty(_minimumPlayerToStart)) {
                if (parseInt(_minimumPlayerToStart?.value) > parseInt(e.value)) {
                    _minimumPlayerToStart = "";
                } else {
                    _minimumPlayerToStart = _minimumPlayerToStart;
                }

            }
            setRecord(pre => ({ ...pre, playerCount: e, totalNumberOfWinners: "", minimumPlayerToStart: _minimumPlayerToStart }));

            let _minPlayerToStartKeyValueOptions = playerCountKeyPairOptions.filter(option => {
                if (process.env.REACT_APP_Allow_Min_Player_To_Start === 'true') {
                    return option.value <= e.value;
                } else {
                    return option.value === e.value;
                }
            });
            setMinPlayerToStartKeyValueOptions(_minPlayerToStartKeyValueOptions)

            // let temp = [];
            // for (let i = 1; i < e.value; i++) {
            //     temp.push({ value: i, label: i, });
            // } // Shashi
            // temp.push({ value: 1, label: 1, });
            // setWinnerOptions(temp)
            // setMinPlayerToStartKeyValueOptions([]);
        } else if (key === "limit") {
            let limitValue = 0;
            if (e.value === "TimeLimit") {
                limitValue = 120;
            } else if (e.value === "TurnLimit" || e.value === "ScoreLimit") {
                limitValue = 20;
            } else {
                limitValue = 0;
            }
            setRecord(pre => ({ ...pre, [key]: e, limitValue: limitValue }));
        } else if (key === "tokenStartPosition" && e.value === "OutSideHome") {
            setRecord(pre => ({ ...pre, [key]: e, startBySixOnly: false }));
        } else if (key === "minimumPlayerToStart") {
            setRecord(prevRecord => ({
                ...prevRecord,
                [key]: e,
                totalNumberOfWinners: "",
            }));

            // Set winnerOptions based on the selected minimum player to start value
            let minPlayerToStartValue = e.value;
            let temp = [];
            if (process.env.REACT_APP_Allow_Multi_Winners === "true") {
                for (let i = 1; i <= minPlayerToStartValue - 1; i++) {
                    temp.push({ value: i, label: i });
                }
            } else {
                temp.push({ value: 1, label: 1 });
            }
            setWinnerOptions(temp);
        } else if (key === "category") {
            setRecord({
                ...record,
                category: e
            });
        }
        setRecord(pre => ({ ...pre, [key]: e }));
    }

    // check Input Validation
    const checkInputValidation = (recordObj, d) => {
        if (isEmpty(recordObj.templateTitle)) {
            toast.error("Title must not be empty.")
            return false;
        } else if (isEmpty(recordObj.betAmount)) {
            toast.error("Bet Amount must not be empty.")
            return false;
        } else if (recordObj.betAmount < 0) {
            toast.error("Bet Amount must not be negative.")
            return false;
            // }
            //  else if (isEmpty(recordObj.feeAmount)) {
            //     toast.error("Fee Amount must not be empty.")
            //     return false;
        } else if (recordObj.feeAmount < 0) {
            toast.error("Fee Amount must not be negative.")
            return false;
        } else if (isEmpty(recordObj.playerCount)) {
            toast.error("Player count must not be empty.")
            return false;
        } else if (isEmpty(recordObj.totalNumberOfWinners)) {
            toast.error("Total Winner must not be empty.")
            return false;
        } else if (isEmpty(recordObj.minimumPlayerToStart)) {
            toast.error("Minimum player to start must not be empty.")
            return false;
        } else if (isEmpty(d)) {
            toast.error("Winner Winning Percentage must not be empty.")
            return false;
        } else if (d > 100) {
            toast.error("Winner Winning Percentage must not be more then 100.")
            return false;
        } else if (isEmpty(recordObj.limit)) {
            toast.error("Limit Type must not be empty.")
            return false;
        } else if (recordObj.limit === "TimeLimit" && recordObj.limitValue < 120) {
            toast.error("Limit Value must not be less then 120 seconds.")
            return false;
        } else if (recordObj.limit === "TurnLimit" && recordObj.limitValue < 20) {
            toast.error("Limit Value must not be less then 20 seconds.")
            return false;
        } else if (isEmpty(recordObj.tokenCount)) {
            toast.error("Token count must not be less then 20 seconds.")
            return false;
        } else if (isEmpty(recordObj.tokenStartPosition)) {
            toast.error("Token Start Position must not empty.")
            return false;
        } else if (isEmpty(recordObj.turnTime)) {
            toast.error("Turn Time must not empty.")
            return false;
        } else if (recordObj.turnTime < 0) {
            toast.error("Turn Time must not negative.")
            return false;
        } else if (isEmpty(recordObj.graceTime)) {
            toast.error("Grace Time must not empty.")
            return false;
        } else if (recordObj.graceTime < 0) {
            toast.error("Grace Time must not negative.")
            return false;
        } else if (recordObj.gameStartWaitingTime < 0) {
            toast.error("Game Start Waiting Time must not negative.")
            return false;
        } else if (isEmpty(recordObj.resultWaitingTime)) {
            toast.error("Result Waiting Time must not empty.")
            return false;
        } else if (recordObj.resultWaitingTime < 2) {
            toast.error("Result Waiting Time must be equal or more to 2 Sec.")
            return false;
        }
        return true;
    }
    // Submit the Template data 
    const handleSubmit = () => {
        try {
            let d = 0;
            winnersWinningPercentageInput && winnersWinningPercentageInput.map((data) => { d += data; });

            let recordObj = {
                ...record,
                boardType: record.boardType?.value || "REGULAR",
                everyWinnerWinningPercentage: winnersWinningPercentageInput,
                playerCount: record.playerCount?.value || 0,
                totalNumberOfWinners: record.totalNumberOfWinners?.value || 0,
                minimumPlayerToStart: record.minimumPlayerToStart?.value || 0,
                limit: record.limit?.value || "",
                tokenCount: record.tokenCount?.value || 0,
                tokenStartPosition: record.tokenStartPosition?.value || "",
                botLevelType: process.env.REACT_APP_Bot_Allowed === "true" ? record.botLevelType?.value : "None",
                category: process.env.REACT_APP_Category_Allowed ? (record.category?.value || "Other") : "Other"
            };
            if (!checkInputValidation(recordObj, d)) {
                return;
            }

            if (!recordId || recordId === "") {
                callPostApi(Save_Ludo_Game_Template_Post, recordObj, {}, (response) => {
                    toast.success(response.message);
                    hideModal();
                    handleRefresh()
                })
            } else {
                callPutApi(Update_Ludo_Game_Template_Put.replaceAll("{recordID}", recordId), recordObj, {}, (response) => {
                    toast.success(response.message);
                    hideModal();
                    handleRefresh()
                })
            }
        } catch (error) {
            return;
        }
    }

    return (
        <Modal show={show} onHide={() => hideModal()} size="xl">
            <Modal.Header closeButton>
                <Modal.Title> {recordId ? "Update" : "Add New"} Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex row'>
                    <div className='col-lg-9'>
                        <div className="form-group row align-items-center">
                            <div className={`col-lg-${process.env.REACT_APP_Bot_Allowed === "true" ? 3 : 6} my-2 mt-0`}>
                                <label>Board Type *</label>
                                <Select options={boardTypeKeyValeOptions} value={record.boardType} onChange={(e) => handleSelectChange(e, "boardType")} />
                            </div>
                            {process.env.REACT_APP_Bot_Allowed === 'true' ?
                                <div className={`col-lg-3 my-2 mt-0`}>
                                    <label>Bot Type *</label>
                                    <Select options={botTypeOptions} value={record?.botLevelType} onChange={(e) => handleSelectChange(e, "botLevelType")} />
                                </div>
                                : <></>
                            }
                            {
                                process.env.REACT_APP_Category_Allowed === "true" ? <>
                                    <div className="col-lg-3 my-2  mt-0">
                                        <label>Category *</label>
                                        <Select options={categories} value={record.category} onChange={(e) => handleSelectChange(e, "category")} />
                                    </div>
                                </> : <></>
                            }
                            <div className={`col-lg-${process.env.REACT_APP_Category_Allowed === "true" ? 3 : 6} my-2 mt-0`}>
                                <label>Title *</label>
                                <input type="text" className="form-control input-default" id="templateTitle" onChange={handleTextChange} value={record.templateTitle} />
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <div className="col-lg-3 my-2">
                                <label>Bet Amount *</label>
                                <input type="number" className="form-control input-default" id="betAmount" onChange={handleTextChange} value={record.betAmount} />
                            </div>
                            <div className="col-lg-3 my-2">
                                <label>Fee Amount *</label>
                                <input type="number" className="form-control input-default" id="feeAmount" onChange={handleTextChange} value={record.feeAmount} />
                            </div>
                            <div className="col-lg-3 my-2">
                                <label>Player Count *</label>
                                <Select options={playerCountKeyPairOptions} value={record.playerCount} onChange={(e) => handleSelectChange(e, "playerCount")} />
                            </div>
                            <div className="col-lg-3 my-2">
                                <label>Min. Player to Start *</label>
                                <Select options={minPlayerToStartKeyValueOptions} value={record.minimumPlayerToStart} onChange={(e) => handleSelectChange(e, "minimumPlayerToStart")} />
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <div className="col-lg-4 my-2">
                                <label>Total Winner *</label>
                                <Select options={winnerOptions} value={record.totalNumberOfWinners} onChange={(e) => handleWinnersCount(e)} />
                            </div>
                            <div className="col-lg-8 my-2 p-0">
                                <label> Winner Winning % *</label>
                                <div className="row d-flex winner-percentage-row">
                                    {winnersWinningPercentageInput.map((row, i) =>
                                        <div key={`winner-winning-percentage-${i}`}>
                                            <li style={{ listStyle: "none" }}>
                                                <input type="number" className="form-control input-sm" name={"key" + i} value={row ? row : ""} onChange={(e) => handleWinnersWinningPercentage(e, i)}
                                                    placeholder={`W${i + 1} %`} />
                                            </li>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <div className="col-lg-3 my-2">
                                <label>Limit Type *</label>
                                <Select
                                    options={limitTypeOptions}
                                    value={record.limit}
                                    onChange={(e) => handleSelectChange(e, "limit")}
                                />
                            </div>
                            <div className="col-lg-3 my-2">
                                <label>Limit Value *</label>
                                <input disabled={record.limit === "" || record.limit.value === "NoLimit"} type="number" className="form-control input-default" id="limitValue" onChange={handleTextChange} value={record.limitValue} />
                            </div>
                            <div className="col-lg-3 my-2">
                                <label>Token Count *</label>
                                <Select options={tokenCountOptions} value={record.tokenCount} onChange={(e) => handleSelectChange(e, "tokenCount")} />
                            </div>
                            <div className="col-lg-3 my-2">
                                <label>Start Token Position *</label>
                                <Select options={tokenStartPositionTypeOptions} value={record.tokenStartPosition} onChange={(e) => handleSelectChange(e, "tokenStartPosition")} />
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <div className="col-lg-6 my-2">
                                <input disabled={record.tokenStartPosition?.value !== "InSideHome"} type="checkbox" className="input-default me-2" id="startBySixOnly" onChange={handleTextChange} checked={record.startBySixOnly} />
                                <label htmlFor='startBySixOnly'>Start By Six Only</label>
                            </div>
                            <div className="col-lg-6 my-2">
                                <input type="checkbox" className="input-default me-2" id="skipDiceTurnIfNotRolled" onChange={handleTextChange} checked={record.skipDiceTurnIfNotRolled} />
                                <label htmlFor='skipDiceTurnIfNotRolled'>Skip Dice Turn If Not Rolled</label>
                            </div>
                            <div className="col-lg-6 my-2">
                                <input type="checkbox" className="input-default me-2" id="skipPlayTurnIfNotPlayed" onChange={handleTextChange} checked={record.skipPlayTurnIfNotPlayed} />
                                <label htmlFor='skipPlayTurnIfNotPlayed'>Skip Play Turn If Not Played</label>
                            </div>
                            <div className="col-lg-6 my-2">
                                <input type="checkbox" className="input-default me-2" id="capturePointsOnTokenCapture" onChange={handleTextChange} checked={record.capturePointsOnTokenCapture} />
                                <label htmlFor='capturePointsOnTokenCapture'>Capture Points On Token Capture</label>
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <div className="col-lg-3 my-2">
                                <label>Turn Time *</label>
                                <input type="number" className="form-control input-default" id="turnTime" onChange={handleTextChange} value={record.turnTime} />
                            </div>
                            <div className="col-lg-3 my-2">
                                <label>Grace Time *</label>
                                <input type="number" className="form-control input-default" id="graceTime" onChange={handleTextChange} value={record.graceTime} />
                            </div>
                            <div className="col-lg-3 my-2">
                                <label title='Waiting Time'>Game Start WT *</label>
                                <input type="number" className="form-control input-default" id="gameStartWaitingTime" onChange={handleTextChange} value={record.gameStartWaitingTime} />
                            </div>
                            <div className="col-lg-3 my-2">
                                <label title='Waiting Time'>Result WT *</label>
                                <input type="number" className="form-control input-default" id="resultWaitingTime" onChange={handleTextChange} value={record.resultWaitingTime} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div>Board Rules</div>
                        <div className="form-group col-lg-12">
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: {
                                        items: ["numberedList",],
                                    },
                                }}
                                data={record?.boardRule}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setRecord(prevRecord => ({ ...prevRecord, boardRule: data }));
                                }}
                            />
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TemplateAddEditNew;